/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  TablesCustomers,
} from "../../../../_start/partials/widgets";
import { useParams } from "react-router-dom";

export const CustomersPage: React.FC = () => {
  // const [show, setShow] = useState(false);

  const { action } = useParams<{ action: string }>();

  useEffect(() => {
    if (action !== undefined) {
      // setShow(false);
    }
  }, [action]);

  return (
    <>
      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <TablesCustomers className="card-stretch mb-5 mb-xxl-8" />
        </div>
      </div>
    </>
  );
};
