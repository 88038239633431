import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import { LightDashboardWrapper } from "../pages/dashboards/light-dashboard/LightDashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { StartDashboardPage } from "../pages/dashboards/start-dashboard/StartDashboardPage";
import {
  IThemeConfig,
  useTheme,
  getConfig,
} from "../../_start/layout/core";
import { CustomersPage } from "../pages/dashboards/start-dashboard/CustomersPage";

const defaultPageConfig = getConfig();
const dashboardPageConfig: Partial<IThemeConfig> = {
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: false,
  },
};


export function PrivateRoutes() {
  const ProfilePageWrapper = lazy(
    () => import("../modules/profile/ProfilePageWrapper")
  );
  const GeneralPageWrapper = lazy(
    () => import("../modules/general/GeneralPageWrapper")
  );
  const DocsPageWrapper = lazy(() => import("../modules/docs/DocsPageWrapper"));

  const { setTheme } = useTheme();
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(dashboardPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path="/" component={StartDashboardPage} />
        <Route exact path="/sms" component={StartDashboardPage} />
        <Route exact path="/push" component={StartDashboardPage} />
        
        <Route exact path="/customers" component={CustomersPage} />
        <Route exact path="/customers/:action" component={CustomersPage} />

        <Route path="/light" component={LightDashboardWrapper} />
        <Route path="/general" component={GeneralPageWrapper} />
        <Route path="/profile" component={ProfilePageWrapper} />
        <Route path="/menu-test" component={MenuTestPage} />
        <Route path="/docs" component={DocsPageWrapper} />
        <Redirect from="/auth" to="/" />
        {/* <Redirect exact from="/" to="/" /> */}
        {/* <Redirect to="/" /> */}
      </Switch>
    </Suspense>
  );
}
